import { breakpoints } from 'src/styles/breakpoints'
import { laranja, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #F7CA96 ;

  h2, h3 {
    color: ${laranja.terra} ;
    font-family: 'Citrina', Helvetica, sans-serif;
  }
`
export const Button = styled.button`
  background: ${orange.base} ;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 32px;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 612px;
  }

`
